:root {
  /* Colors */
  --color-bg: var(--color-day-bg);
  --color-fg: var(--color-day-fg);
  --color-text: var(--color-day-text);
  --color-primary: var(--color-day-primary);
  --color-primary-transparent: rgba(36, 212, 140, 0.33);

  --color-complement: var(--color-day-complement);

  --color-danger: #eb0a36;

  --color-success: #14cc67;

  --color-dollar-red: #cc141d;

  --color-dollar-green: #0f9e50;

  --color-shadow: var(--color-day-shadow);

  --color-active: var(--color-day-active);

  --color-text-light: var(--color-text-light-day);

  --color-gold: #c9b037;
  --color-silver: #b4b4b4;
  --color-bronze: #ad8a56;

  /* Holder Color Vars */
  --color-night-bg: #000000;
  --color-night-fg: #222222; /* this is what the app has */
  --color-night-fg: hsl(0, 0%, 16%);
  --color-night-primary: #24d48c;
  --color-night-complement: #6c9ceb;
  --color-night-text: #eeeeee;
  --color-night-active: rgba(250, 250, 250, 0.1);
  --color-night-text-light: rgba(238, 238, 238, 0.25);

  --color-day-bg: #eeeeee;
  --color-day-fg: #000000;
  /*--color-day-primary: #24d48c;*/
  --color-day-primary: #40a0db;
  --color-day-complement: #6c9ceb;
  --color-day-text: #222831;
  --color-day-active: rgba(0, 0, 0, 0.1);
  --color-day-text-light: rgba(34, 40, 49, 0.1);

  --color-night-shadow: rgba(36, 212, 140, 0.25);
  --color-day-shadow: rgba(55, 58, 75, 0.2);

  /* radii */
  --btn-border-radius: 25px;
  --std-border-radius: 8px;

  /* other */
  --std-transition: 0.15s ease-in-out;

  /* Text Sizing */
  --text-base-size: 1rem;
  --text-scale-ratio: 1.175;
  --text-xs: calc((1em / var(--text-scale-ratio)) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
  --text-md: calc(
    var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio)
  );
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));
  --text-xxxxl: calc(var(--text-xxxl) * var(--text-scale-ratio));

  /* Space */
  --space-unit: 1rem;
  --space-xxxxs: calc(0.125 * var(--space-unit));
  --space-xxxs: calc(0.25 * var(--space-unit));
  --space-xxs: calc(0.375 * var(--space-unit));
  --space-xs: calc(0.5 * var(--space-unit));
  --space-sm: calc(0.75 * var(--space-unit));
  --space-md: calc(1.25 * var(--space-unit));
  --space-lg: calc(2 * var(--space-unit));
  --space-xl: calc(3.25 * var(--space-unit));
  --space-xxl: calc(5.25 * var(--space-unit));
  --space-xxxl: calc(8.5 * var(--space-unit));
  --space-xxxxl: calc(13.75 * var(--space-unit));

  /* Default transition */
  --transition: all 0.15s ease-in-out;
}

@import url("https://fonts.googleapis.com/css?family=Noto+Sans");

/* html {
  scroll-behavior: smooth;
} */

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans");
}

body {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans", sans-serif;
  font-size: var(--text-base-size);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: var(--color-bg);
  color: var(--color-text);
  /*min-height: 100vh;*/
  transition: background var(--std-transition), color var(--std-transition);
}

* {
  font-family: "Noto Sans", sans-serif;
  box-sizing: border-box;
  scrollbar-width: 4px;
  scrollbar-color: #888 transparent;
}

svg {
  font-family: unset;
  box-sizing: unset;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #777;
}
@media only screen and (max-width: 1366px) {
  ::-webkit-scrollbar {
    width: unset;
  }

  ::-webkit-scrollbar-track {
    background: unset;
  }

  ::-webkit-scrollbar-thumb {
    background: unset;
    border-radius: unset;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: unset;
  }
}

/* Text */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  letter-spacing: -0.1rem;
  font-family: "Noto Sans", sans-serif;
  margin-bottom: var(--space-xs);
}
h1 {
  font-size: var(--text-xxxxl);
  line-height: 1.125;
}
h2 {
  font-size: var(--text-xxxl);
  line-height: 1.13;
}
h3 {
  font-size: var(--text-xxl);
  line-height: 1.135;
}
h4 {
  font-size: var(--text-xl);
  line-height: 1.14;
}
h5 {
  font-size: var(--text-lg);
  line-height: 1.15;
  letter-spacing: -0.05rem;
}
h6 {
  font-size: var(--text-md);
  line-height: 1.2;
  margin-top: 0;
  letter-spacing: -0.025rem;
}
p {
  font-size: var(--text-base-size);
}
a {
  color: var(--color-text);
}
small {
  line-height: 1.1;
}

/* HR */
hr {
  border-width: 0;
  border-top: var(--space-xxxxs) solid var(--color-primary);
  margin: var(--space-lg) 0;
}

/* Containers */
.container {
  position: relative;
  width: 70vw;
  min-height: 75vh;
  margin: 0 auto;
  box-sizing: border-box;
}
.container-full {
  position: relative;
  width: 98vw;
  min-height: 75vh;
  margin: 0 auto;
  box-sizing: border-box;
}
@media only screen and (max-width: 1366px) {
  .container {
    width: 100%;
    padding: 0;
  }
}
.img-responsive {
  width: 100%;
  height: auto;
}

/* Modal */
.ReactModal__Overlay {
  opacity: 0;
  height: 0 !important;
  top: -100% !important;
  transition: all 150ms ease-in-out;
  z-index: 1000;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  height: 100% !important;
  top: 0 !important;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  height: 0 !important;
  top: -100% !important;
}

@media only screen and (max-width: 768px) {
  .ReactModal__Overlay {
    opacity: 1;
    height: 0 !important;
    top: 100% !important;
    transition: all 150ms ease-in-out;
    z-index: 1000;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
    height: 100% !important;
    top: 0 !important;
  }

  .ReactModal__Overlay--before-close {
    opacity: 1;
    height: 0 !important;
    top: 100% !important;
  }
}

/* flashes */
@keyframes FlashOddGreen {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: var(--color-success);
  }
  100% {
    background-color: transparent;
  }
}

@keyframes FlashOddRed {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: var(--color-danger);
  }
  100% {
    background-color: transparent;
  }
}

.flash-odd-green {
  animation-name: FlashOddGreen;
  animation-duration: 750ms;
  animation-timing-function: ease-in-out;
}

.flash-odd-red {
  animation-name: FlashOddRed;
  animation-duration: 750ms;
  animation-timing-function: ease-in-out;
}

@keyframes Flash {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: var(--color-text);
  }
  100% {
    background-color: transparent;
  }
}

.flash {
  animation-name: Flash;
  animation-duration: 1000ms;
  animation-timing-function: ease-in-out;
  animation-delay: 333ms;
}

@keyframes FlashPrimary {
  0% {
    background-color: transparent;
    box-shadow: 0 0 0 0 white;
    color: unset;
  }
  50% {
    background-color: var(--color-primary-transparent);
    color: white;
    box-shadow: 0 2px 16px 4px var(--color-primary);
  }
  100% {
    background-color: transparent;
    box-shadow: 0 0 0 0 white;
    color: unset;
  }
}

.flash-primary {
  animation-name: FlashPrimary;
  animation-duration: 1000ms;
  animation-timing-function: ease-in-out;
  animation-delay: 100ms;
}

/* one filter */
.initial-one-filter-position {
  left: 100%;
  width: 100%;
}

@keyframes AnimateAnalysisFilterOpen {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}

.animate-analysis-filter-open {
  left: 0;
  width: 100%;
  animation-name: AnimateAnalysisFilterOpen;
  animation-duration: 250ms;
  animation-timing-function: ease-in-out;
}

@keyframes AnimateAnalysisFilterClose {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

.animate-analysis-filter-close {
  left: 100%;
  width: 100%;
  animation-name: AnimateAnalysisFilterClose;
  animation-duration: 250ms;
  animation-timing-function: ease-in-out;
  overflow-y: hidden;
}

/* all filters */
@keyframes AnimateAnalysisAllFiltersOpen {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}

.animate-analysis-all-filters-open {
  left: 0;
  width: 100%;
  animation-name: AnimateAnalysisAllFiltersOpen;
  animation-duration: 250ms;
  animation-timing-function: ease-in-out;
}

@keyframes AnimateAnalysisAllFiltersClose {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

.animate-analysis-all-filters-close {
  left: -100%;
  width: 100%;
  animation-name: AnimateAnalysisAllFiltersClose;
  animation-duration: 250ms;
  animation-timing-function: ease-in-out;
}

/* react-date-picker customizations */
.calendar > .react-date-picker__wrapper {
  border-radius: var(--std-border-radius);
  padding: var(--space-xxs);
  border: none;
}

.calendar > .react-daterange-picker__wrapper {
  border-radius: var(--std-border-radius);
  padding: var(--space-xxs);
  border: none;
}

.react-daterange-picker__range-divider {
  padding-right: 4px;
  padding-left: 4px;
}

.react-daterange-picker__inputGroup {
  text-align: center;
}

.react-daterange-picker__inputGroup > input {
  color: var(--color-text);
}

.react-daterange-picker__inputGroup__month {
  color: var(--color-text);
}

.react-daterange-picker__button > svg {
  stroke: var(--color-text);
}

.react-date-picker__inputGroup > input {
  color: var(--color-text);
}
.react-date-picker__inputGroup__input {
  color: var(--color-text);
}
.react-date-picker__button > svg {
  stroke: var(--color-text);
}

.hide-calendar-inputs > .react-date-picker__wrapper {
  display: none;
}

.underline-on-hover {
  text-decoration: none;
}
.underline-on-hover:hover {
  text-decoration: underline !important;
}

.react-switch-selector-option {
  min-height: 36px;
}

/* survey overrides */
.sv-container-modern__title {
  padding-top: var(--space-xxs) !important;
}

.sv_progress-buttons__list
  li.sv_progress-buttons__list-element--passed::before {
  border-color: var(--color-primary) !important;
  background-color: var(--color-primary) !important;
}

.sv_progress-buttons__list
  li.sv_progress-buttons__list-element--current::before {
  border-color: var(--color-primary) !important;
  background-color: var(--color-bg) !important;
}

.sv_progress-buttons__list li::before {
  border-color: var(--color-primary-transparent) !important;
  background-color: var(--color-primary-transparent) !important;
}

.sv_progress-buttons__list
  li.sv_progress-buttons__list-element--passed
  + li::after {
  background-color: var(--color-primary-transparent) !important;
}

.sv-table tbody tr:last-child .sv-table__cell {
  padding-bottom: 1rem !important;
}
.sv-table tr:first-child .sv-table__cell {
  padding-top: 1rem !important;
}

.sv-body__page {
  margin-top: 1rem !important;
}
